<template>
  <el-upload action="" :auto-upload="false" :on-change="fileChange" :show-file-list="false" :disabled="isUploading">
    <template v-if="!isUploading">
      <el-button type="primary" >点击上传</el-button>
    </template>
    <template v-else>
      <el-button type="primary" :loading="true" :disabled="true">正在上传</el-button>
    </template>
  </el-upload>
</template>

<script>
  import {
    createNamespacedHelpers, mapActions
  } from 'vuex'
  const upload = createNamespacedHelpers('subscribeMessage')
  export default {
    model:{
      prop: 'resourceUrl',
      event: 'uploadSuccessfully'
    },
    props: {
      resourceUrl:{
        type: String,
        default: ""
      }
    },
    data() {
      return {
        isUploading: false
      }
    },
    computed: {
    },
    methods: {
      ...upload.mapActions(['uploadImage']),
      fileChange(file, fileList) {
        if(file)
        {
          this.isUploading = true
          fileList = [{...file}]
          this.uploadFileToWechat(file)
        }
      },
      uploadFileToWechat(file) {
        this.uploadImage(file)
        .then(res => {
          this.$emit('uploadSuccessfully', res.data)
          this.$message.success('上传成功')
        })
        .catch(error => {
          this.$message.error('上传失败')
        })
        .finally(() => {
          this.isUploading = false
        })
      }
    },
    mounted() {
    },
  }
</script>

<style scoped>
</style>