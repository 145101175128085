<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>课程列表</span>
        <el-button type="primary" style="float: right" size="mini" @click="handleAdd()" icon="el-icon-circle-plus-outline">添加课程</el-button>
      </div>
      <div>
        <el-input clearable style="width:30%;float:right" type="text" v-model="search" placeholder="输入课程名称检索">
        </el-input>
        <el-table
          :data="coursesList.filter(data => !search || data.courseName.toLowerCase().includes(search.toLowerCase()))"
          style="width: 100%">
          <el-table-column prop="courseName" label="名称" sortable>
          </el-table-column>
          <el-table-column prop="title" label="标题">
          </el-table-column>
          <el-table-column align="right" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="handleEdit(scope.row)" icon="el-icon-edit">修改</el-button>
              <el-button size="mini" type="danger" @click="handleDelete(scope.row)" icon="el-icon-circle-close">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <CoursesModel v-model="showAddModel" @submit="submit" />
  </div>
</template>

<script>
  import CoursesModel from './CoursesModel'
  import {
    createNamespacedHelpers
  } from 'vuex'
  const courses = createNamespacedHelpers('courses')
  const admin = createNamespacedHelpers('admin')

  export default {
    name: "Home",
    components: {
      CoursesModel,
    },
    data() {
      return {
        addForm: {
          courseName: '',
          title: '',
          description: ''
        },
        showAddModel: false,
        search: '',
      }
    },
    computed: {
      ...courses.mapGetters(['allCourses']),
      ...admin.mapGetters(['userInfo']),
      coursesList() {
        return this.allCourses
      },
      canSubmit() {
        return this.addForm.courseName && this.addForm.title && this.addForm.description
      }
    },
    watch: {},
    methods: {
      ...courses.mapActions(['getAllCourses', 'addCourses', 'deleteCourses']),
      handleAdd() {
        this.showAddModel = true
      },
      handleDelete(data) {
        this.$confirm(`确认删除${data.courseName}?`, '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.deleteCourses(data.id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          }).catch(() => {
            this.$message.error('删除失败')
          })
        }).catch(() => {
          this.$message('取消删除')
        })
      },
      handleEdit(data) {
        this.$router.push({
          name: 'CoursesManagement',
          params: {
            name: data.id,
            data: data,
          }
        })
      },
      submit(form) {
        this.addForm = form
        this.addForm.InstructorId = this.userInfo.instructorId
        this.submitAdd()
      },
      submitAdd() {
        this.addCourses(this.addForm).then(() => {
          this.$message({
            type: 'success',
            message: '添加成功'
          })
          this.showAddModel = false
        }).catch(() => {
          this.$message.error('添加失败')
          this.showAddModel = false
        })
      },
    },
    mounted() {},
  }
</script>
<style lang="stylus">
  .el-textarea__inner {
    resize: none;
  }
</style>