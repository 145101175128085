<template>
  <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" class="pub_dialog" :title="title"
    :visible.sync="formValue" :width="width">
    <slot name="text"></slot>
    <span slot="footer" class="dialog-footer">
      <slot name="actions"></slot>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String
      },
      width: {
        type: String
      }
    },
    data() {
      return {
        formValue: false,
      }
    },
    watch: {
      value(v) {
        this._updateFormValue(v)
      },
      formValue(v) {
        this._emitChange(v)
      },
    },
    methods: {
      _updateFormValue(v) {
        this.formValue = v
      },
      _emitChange(v) {
        this.$emit('input', v)
      },
      close() {
        this._emitChange(false)
      },
    },
    mounted() {
      this._updateFormValue(this.value)
    },
  }
</script>

<style scoped>
</style>