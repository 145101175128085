<template>
  <Dialog v-model="formValue" :title="title" :width="width">
    <template v-slot:text>
      <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px">
        <el-form-item label="名称" prop="courseName">
          <el-input clearable v-model="form.courseName" prefix-icon="el-icon-discount"></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input clearable v-model="form.title" prefix-icon="el-icon-document"></el-input>
        </el-form-item>
        <el-form-item label="详情描述" prop="description">
          <el-input clearable v-model="form.description" type="textarea" :autosize="{ minRows: 2}"
            style="resize: none;">
          </el-input>
        </el-form-item>
        <el-form-item label="背景图片" prop="backgroundImage">
          <el-input clearable v-model="form.backgroundImage" prefix-icon="el-icon-discount"></el-input>
          <div class="btn_group">
            <uploadImage style="float: left;" v-model="form.backgroundImage"></uploadImage>
          </div>
        </el-form-item>
      </el-form>
    </template>
    <template v-slot:actions>
      <el-button @click="formValue = false">取消</el-button>
      <el-button type="primary" @click="submit()" :disabled="!canSubmit" icon="el-icon-circle-check">提交</el-button>
    </template>
  </Dialog>
</template>

<script>
  import Dialog from './Dialog'
  import uploadImage from './UploadImage'
  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      data: {
        type: Object,
      }
    },
    components: {
      Dialog,
      uploadImage
    },
    data() {
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('详情描述不能为空'))
        }
      }
      var checkCourseName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('名称不能为空'))
        }
      }
      var checkTitle = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('标题不能为空'))
        }
      }
      return {
        formValue: false,
        width: '40%',
        form: {
          courseName: '',
          title: '',
          description: '',
          instructorId: 1,
          backgroundImage: '',
        },
        rules: {
          courseName: [{
            validator: checkCourseName,
            trigger: 'blur'
          }],
          title: [{
            validator: checkTitle,
            trigger: 'blur'
          }],
          description: [{
            validator: checkDescription,
            trigger: 'blur'
          }]
        },
        isEdit: false,
      }
    },
    computed: {
      title() {
        return this.isEdit ? '修改课程-录入信息' : '添加课程-录入信息'
      },
      canSubmit() {
        return this.form.courseName && this.form.title && this.form.description
      }
    },
    watch: {
      value(v) {
        this._updateFormValue(v)
      },
      formValue(v) {
        this._emitChange(v)
      },
      data(v) {
        if (Object.prototype.hasOwnProperty.call(v, 'id')) {
          this.isEdit = true
          this.form = v
        } else {
          this.clearAll()
        }
      }
    },
    methods: {
      clearAll() {
        this.isEdit = false
        this.form = {
          courseName: '',
          title: '',
          description: '',
          backgroundImage: '',
        }
      },
      _updateFormValue(v) {
        this.formValue = v
      },
      _emitChange(v) {
        this.$emit('input', v)
        if (!v) {
          this.clearAll()
        }
      },
      submit() {
        this.$emit('submit', this.form)
      },
    },
    mounted() {
      this._updateFormValue(this.value)
    },
  }
</script>

<style scoped>
.btn_group {
    padding: 10px 0;
    text-align: right;
    box-sizing: border-box;
  }
</style>